import type {NextPage} from 'next';
import PageWithLayoutType from 'types/pageWithLayout';
import Blank from '@layouts/Blank';

const Home: NextPage = () => {
  return null;
};

(Home as PageWithLayoutType).layout = Blank;

export default Home;
